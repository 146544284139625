const settingOrganization = {
  'pages.settings.organization.add.organization': 'Add Organization',
  'pages.settings.organization.superior.organization': 'Superior Organization',
  'pages.settings.organization.organization.name': 'Organization Name',
  'pages.settings.organization.organization.type': 'Type',
  'pages.settings.organization.organization.address': 'Address',
  'pages.settings.organization.organization.owner': 'Owner',
  'pages.settings.organization.organization.owner.phone': 'Owner Phone',
  'pages.settings.organization.organization.main.verify': 'Main Verify',
  'pages.settings.organization.organization.anew.verify': 'Anew Verify',
  'pages.settings.organization.organization.abbreviation.name': 'Organization Abbreviation Name',
  'pages.settings.organization.organization.code': 'Organization Code',
  'pages.settings.organization.organization.concat.name': 'Concat Name',
  'pages.settings.organization.organization.concat.phone': 'Concat Phone',
  'pages.settings.organization.organization.approval.status': 'Approval Status',
  'pages.settings.organization.organization.basic.information': 'Basic Information',
  'pages.settings.organization.organization.invoice.information': 'Invoice Information',
  'pages.settings.organization.organization.company.name': 'Company Name',
  'pages.settings.organization.organization.company.address': 'Company Address',
  'pages.settings.organization.organization.company.detail.address': 'Detail Address',
  'pages.settings.organization.organization.social.credit.code': 'Social Credit Code',
  'pages.settings.organization.organization.company.register.address': 'Company Register Address',
  'pages.settings.organization.organization.company.register.phone': 'Company Register Phone',
  'pages.settings.organization.organization.deposit.bank': 'Deposit Bank',
  'pages.settings.organization.organization.deposit.bank.account': 'Deposit Bank Account',
  'pages.settings.organization.organization.company.certificate.address': 'Certificate Address',
  'pages.settings.organization.organization.corporate.identity.card': 'Corporate Identity Card',
  'pages.settings.organization.organization.business.license': 'Business License',
  'pages.settings.organization.organization.official.letter': 'Official.letter',
  'pages.settings.organization.organization.more.operation': 'More Operation',
  'pages.settings.organization.organization.menu.configuration': 'Menu Configuration',
  'pages.settings.organization.organization.turn.over': 'Turn  Over',
  'pages.settings.organization.organization.delete.company': 'Delete Company',
  'pages.settings.organization.organization.not.verify': 'Organization Not Verify',
  'pages.settings.organization.organization.immediately.verify': 'Immediately Verify',
  'pages.settings.organization.organization.legal.person': 'Legal Person',
}

export default settingOrganization
